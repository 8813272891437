import posthog from 'posthog-js'

/**
 * Registra un evento genérico relacionado con alguna parte del flujo de FitnessCheck en PostHog.
 *
 * @param action - La acción que se está rastreando.
 */

/** Los eventos están en orden de pantalla, por favor respeta el orden */

function trackEventFitnessCheck(
	action: string,
	properties?: Record<string, string | number>,
): void {
	posthog.capture(`fitnessCheck_${action}`, properties)
}

const trackEventRedirectionFromSpecialist = () => {
	trackEventFitnessCheck('redirection_from_specialist')
}

const trackEventEntryPage = () => {
	trackEventFitnessCheck('entry_page')
}

const trackEventEntryPageFromHomeClosed = () => {
	trackEventFitnessCheck('entry_page_from_promotional_banner_closed')
}

const trackEventEntryPageClosed = () => {	
	trackEventFitnessCheck('entry_page_from_module_closed')
}

const trackEventEntryPageFromHomeOpened = () => {
	trackEventFitnessCheck('entry_page_from_promotional_banner_opened')
}

const trackEventAcceptTyCAndRedirect = () => {
	trackEventFitnessCheck('accept_tyc_and_redirect')
}

const trackEventSelectPlan = () => {
	trackEventFitnessCheck('select_plan')
}

const trackEventSelectDependant = () => {
	trackEventFitnessCheck('select_dependant')
}

const trackEventSelectDependantError = ({
	selectValueParams,
	patientUid
}) => {
	trackEventFitnessCheck('select_dependant_error', {
		selectValueParams,
		patientUid,
	})
}

const trackEventConfirmSelectCoverage = ({
	patientUid,
	patientCorporate,
}) => {
	trackEventFitnessCheck('confirm_select_coverage', {
		patientUid,
		patientCorporate,
	})
}

const trackEventUserFiles = ({ ...props}) => {
	trackEventFitnessCheck('check_uploaded_user_files', { ...props})
}

const trackEventErrorInURLFile = ({ ...props}) => {
	trackEventFitnessCheck('error_in_url_file', { ...props})
}

export const fitnessCheckEvents = {
	trackEventRedirectionFromSpecialist,
	trackEventEntryPage,
	trackEventEntryPageFromHomeClosed,
	trackEventEntryPageClosed,
	trackEventEntryPageFromHomeOpened,
	trackEventAcceptTyCAndRedirect,
	trackEventSelectPlan,
	trackEventSelectDependant,
	trackEventSelectDependantError,
	trackEventConfirmSelectCoverage,
	trackEventUserFiles, 
	trackEventErrorInURLFile
}


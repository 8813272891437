'use client'
import React, { useContext, useEffect, useState } from 'react'
import Modules from './Modules/Modules'
import { HomeParametersObject, IPatient } from '@umahealth/entities'
import { IClientState } from '@/config/clients'
import { chatAttContext } from '@/OldRouter/Providers/ProviderChatAtt'
import CountryModal from '../Modals/components/CountryModal'
import NotificationCard from './Components/NotificationCard'
import { usePostHog } from 'posthog-js/react'
import { usePosthogIdentifyClient } from '@/hooks/usePosthogIdentifyClient'
import { saveUtmParams } from '@/context/saveUtmParams'
import { SurveysFlags } from './Utils/surveysFlags'
import PosthogSurveys, { PosthogSurveysType } from '../GeneralComponents/PosthogSurveys/PosthogSurveys'

interface IHomepage {
	homeParameters: HomeParametersObject
	client: IClientState
	country: 'AR' | 'MX' | undefined,
	surveysFlags?: SurveysFlags,
	patient: IPatient
	searchParams: {
		/** utm params para trackear en analytics */
		utm_source?: string,
		/** utm params para trackear en analytics */
		utm_medium?: string,
		/** utm params para trackear en analytics */
		utm_campaign?: string,
	}
}

function Homepage({
	client,
	homeParameters,
	country,
	surveysFlags,
	patient,
	searchParams
}: IHomepage) {
	const chatAtt = useContext(chatAttContext)
	const [countrySelected, setCountrySelected] = useState<string | null>(null)
	const dbCountryCoincidence = patient?.country !== process.env.NEXT_PUBLIC_COUNTRY

	const posthog = usePostHog()
	const [activeSurveys, setActiveSurveys] = useState<PosthogSurveysType>({
		nps: null,
		physicalCert: null,
	})

	usePosthogIdentifyClient(patient)
	
	useEffect(() => {
		setCountrySelected(window.localStorage.getItem('selectedCountry'))
		saveUtmParams({ objSearchParams: searchParams })
	}, [])
	
	useEffect(() => {
		if (patient && posthog && surveysFlags) {
			posthog?.getSurveys((surveys) => {
				const surveysNames = Object.keys(surveysFlags) as Array<keyof SurveysFlags>
				for(const surveyName of surveysNames) {
					if (surveys.length && surveysFlags[surveyName].active) {
						/** buscamos en el array de todas las surveys definidas en posthog, la que corresponda con nuestra flag key */
						const targetingSurvey = surveys.find((survey) => survey.targeting_flag_key === surveysFlags[surveyName].key)
						if (targetingSurvey) {
							setActiveSurveys((prevState) => ({
								...prevState,
								[surveyName]: targetingSurvey,
							}))
						}
					}
				}
			}, true)
		}
	}, [patient, posthog, surveysFlags])

	/**
	 * El modal aparece sí y sólo sí se cumplen estas 3 normas:
	 * El paciente tiene data y además de tener data, su country (del perfil del usuario) difiere del country del entorno env, es decir: patient.data?.country != process.env.NEXT_PUBLIC_COUNTRY
	 * Si no tengo el param country='AR' | country='MX' | country='VE' o lo que sea
	 * Si el valor de localstorage con el country almacenado es diferente a el country del servidor
	 */
	if (
 		dbCountryCoincidence &&
		countrySelected != process.env.NEXT_PUBLIC_COUNTRY &&
		country === undefined

	) {
		return (
			<>
				<CountryModal
					patientCountry={patient?.country}
					serverCountry={process.env.NEXT_PUBLIC_COUNTRY}
				/>
			</>
		)
	}

	return (
		<>
			<Modules
				chatAtt={chatAtt}
				client={client}
				user={patient || null}
				homeParameters={homeParameters}
			/>
			<NotificationCard />
			<PosthogSurveys posthogSurveys={activeSurveys} />
		</>
	)
}

export default Homepage

import { UseQueryOptions, useQuery } from 'react-query'
import { BundleEntry } from '@smile-cdr/fhirts/dist/FHIR-R3'
import { getResource } from '@/app/services/serverServices/FHIR/getResource'

type Queryoptions = Omit<UseQueryOptions<BundleEntry[], unknown, BundleEntry[] , string[]>, 'queryKey' | 'queryFn'>

export const useAdvancedSearchFhirResource = (
	resourceType: string,
	filters: string,
	options?: Queryoptions,
) => {
	return useQuery({
		queryKey: ['FhirSearch', resourceType, filters],
		queryFn: async () => {
			return await getResource({ resourceType, filters })
		},
		onError: (error) => {
			throw new Error(error as string)
		},
		...options,
	})
}

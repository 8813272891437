import React, { useState } from 'react'
import { useRouter } from 'next/navigation'
import { Button, Paragraph } from '@umahealth/occipital'
import { Icon } from '@umahealth/occipital/client'
import ReusableDrawer from '@/components/GeneralComponents/Drawer/Drawer'
import MySubscriptionDrawerContent from './MySubscriptionDrawerContent'
import { useDeletePendingSubscription } from '../../hooks/useDeletePendingSubscription'
import { ISubscriptionWithId } from '@umahealth/fe-firebase/src/Firestore-Query/Subscription/useSuscription'
import { mercadopago_checkout } from '@/config/endpoints'
import { suscriptionEvents } from '@/events/suscriptionsEvents'
type ManageSubscriptionDrawerProps = {
	token: string | undefined
	subscription: ISubscriptionWithId
	onNext: () => void
}

const ManageSubscriptionDrawer = ({
	token,
	subscription,
	onNext,
}: ManageSubscriptionDrawerProps) => {
	const router = useRouter()
	const [selectedOption, setSelectedOption] = useState<string | null>(null)
	const [isOpen, setIsOpen] = useState(true)
	const [stepDeleteSubscription, setStepDeleteSubscription] = useState(false)
	const { deleteSubscription, isLoading, isError, reset } = useDeletePendingSubscription()

	const handleOptionSelect = (option: string) => setSelectedOption(option)

	const handleContinue = () => {
		if (!selectedOption) return
		suscriptionEvents.trackModalSuscriptionsPending('continue', {
			subscriptionId: subscription?.mpPlanId,
			optionId: selectedOption,
		})
		const actions = {
			validate: () => router.push(`/my-subscriptions/validate-subscription/${subscription?.mpPlanId}`),
			finalize: () => router.push(`${mercadopago_checkout}${subscription?.mpPlanId}`),
			delete: () => setStepDeleteSubscription(true),
		}
		actions[selectedOption as keyof typeof actions]?.()
	}

	const handleClose = () => {
		suscriptionEvents.trackModalSuscriptionsPending('close', {
			subscriptionId: subscription?.mpPlanId
		})
		setIsOpen(false)
		onNext()
	}

	const handlePrevious = () => {
		setStepDeleteSubscription(false)
		setSelectedOption(null)
		suscriptionEvents.trackModalSuscriptionsPending('close', {
			subscriptionId: subscription?.mpPlanId,
		})
		if (isError) {
			reset()
		}
	}

	const handleDeleteSubscription = async () => {
		suscriptionEvents.trackModalSuscriptionsPending('delete', {
			subscriptionId: subscription?.mpPlanId,
		})
		await deleteSubscription({ token, documentId: subscription?.docId })
	}

	const renderDescription = () => {
		if (isError) {
		  return (
				<div className='py-4 flex flex-col items-center justify-center gap-4'>
					<Icon name='error' color='text-error' size='size-8' />
					<Paragraph size='text-s'>Error al eliminar la suscripción.</Paragraph>
				</div>
		  )
		}
		if (stepDeleteSubscription && !isError) {
		  return (
				<div className='py-2' />
		  )
		}
		return <MySubscriptionDrawerContent onSelectOption={handleOptionSelect} />
	}

	const renderFooterActions = () => (
		<>
			{stepDeleteSubscription || isError ? (
				<>
					<Button type="button" action={handleDeleteSubscription} className='mt-4' loading={isLoading}>
						{isError ? 'Volver a intentar' : 'Eliminar suscripción'}
					</Button>
					<Button type="button" action={handlePrevious} variant="text">
            			Volver
					</Button>
				</>
			) : (
				<>
					<Button type="button" action={handleContinue} disabled={!selectedOption}>
            			Continuar
					</Button>
					<Button type="button" action={handleClose} variant="text">
            			Omitir por ahora
					</Button>
				</>
			)}
		</>
	)

	return (
		<ReusableDrawer
			title={
				stepDeleteSubscription
					? `Estás por eliminar tu suscripción pendiente ${subscription?.displayName}`
					: `Suscripción ${subscription?.displayName} pendiente, ¿Qué deseas hacer?`
			}
			description={renderDescription()}
			open={isOpen}
			onClose={handleClose}
			footerActions={renderFooterActions()}
		/>
	)
}

export default ManageSubscriptionDrawer
import React from 'react'
import { Button } from '@umahealth/occipital'
import classNames from 'classnames'
import './styles.css'

interface IButtonCustom {
	children: React.ReactNode
	onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
	loading?: boolean
	loadingText?: string
	disabled?: boolean
	type?: 'submit' | 'reset' | 'button'
	variant?: 'filled' | 'text' | 'outlined'
	icon?: React.ReactNode
}

const ButtonCustom = ({
	disabled,
	onClick,
	children,
	loading,
	loadingText,
	type = 'button',
	variant = 'filled',
	icon,
	...rest
}: IButtonCustom) => (
	<Button
		loading={loading}
		loadingText={loadingText}
		className={classNames('text-[15px] !font-thin leading-[21px]', {
			'bg-neutral-200 text-neutral-600': disabled,
		})}
		disabled={disabled}
		type={type}
		variant={variant}
		id='button-custom'
		size="full"
		onClick={onClick}
		{...rest}
	>
		<span className='flex items-center justify-center'>
			{children} {icon ?? null}
		</span>
	</Button>
)

export default ButtonCustom

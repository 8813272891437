'use client'
import React, { useState } from 'react'
import { IMedicalRecord } from '@umahealth/entities'
import { Loader } from '@umahealth/occipital'
import makeSpecialistPath from '@/components/Especialista/Utils/makeSpecialistPath'
import ServiceButton from '@/storybook/components/sharedComponents/ServiceButton/ServiceButton'
import { useGetPendingMedicalRecord } from '@/services/hooks/requests/MedicalRecord/useGetPendingMedicalRecord'
import { differenceInMinutes } from 'date-fns'
import { FarmatodoPaths } from '@/components/Especialista/PharmacoVigilance/Utils/farmatodoPaths'

interface ExtendedMedicalRecord extends IMedicalRecord {
	isSooner: boolean,
	timeLeft: number
}
export type TReasonFarmaAtt = 'cardiovascular' | 'glucose'
interface IFarmatodoSpecialistShortcut {
	reason: TReasonFarmaAtt,
	uid: string,
}

function FarmatodoSpecialistShortcut({ uid, reason }: IFarmatodoSpecialistShortcut) {
	const [ pendingExtendedMrs, setPendingExtendedMrs ] = useState<ExtendedMedicalRecord[]>([])

	const pendingMr = useGetPendingMedicalRecord(uid, {
		onSuccess: (mrs) => {
			const extendedPending = mrs?.map((mr) => {
				const dtAssignationDate = mr.timestamps?.dt_assignation?.toDate();
				if (!dtAssignationDate) {
					return null
				}

				const diffInMinutes = differenceInMinutes(dtAssignationDate, new Date())
				const isSooner = Math.abs(diffInMinutes) < 30

				const extendedMedicalRecord: ExtendedMedicalRecord = {
					...mr,
					isSooner: isSooner,
					timeLeft: Math.abs(diffInMinutes),
				}

				return extendedMedicalRecord
			}).filter((item) => item !== null); // Filtra valores nulos si dt_assignation es inválido

			setPendingExtendedMrs(extendedPending);
		},

	})

	const getMostSoonerAppointment = (pendingExtendedMrs: ExtendedMedicalRecord[]) => {
		return pendingExtendedMrs
			?.filter((record) => record.event_type === 'online' && record.timeLeft)
			?.sort((a, b) => a.timeLeft - b.timeLeft)[0] || null
	}

	const MostSoonerAppointment = getMostSoonerAppointment(pendingExtendedMrs)
	// Si el appointment tiene el turno dentro de 30 minutos, lo llevo a queue
	const isQueue = MostSoonerAppointment && MostSoonerAppointment.isSooner

	if (isQueue) {
		return <ServiceButton
			className="mr-3"
			name='briefcaseMedical'
			href={`marketplace/queue/${MostSoonerAppointment.assignation_id}`}
		>
			Sala de espera
		</ServiceButton>
	}

	if (!isQueue && pendingExtendedMrs?.length) {
		return <ServiceButton
			className="mr-3"
			name='BiCalendar'
			href={`${makeSpecialistPath('pending', {
				dependant: false,
				patientUid: uid ?? 'NO',
				type: 'consultorio',
			})}&reason=${reason}`}
		>
			Agenda tu cita
		</ServiceButton>
	}

	if (pendingMr.isLoading || pendingMr.isIdle) {
		return (
			<div className={'w-[107px] h-[77px] border border-solid border-grey-5 text-primary rounded-md flex items-center flex-col align-middle content-center justify-center py-3 px-4 mr-3'}>
				<Loader size={'size-5'} color='stroke-primary' />
			</div>
		)
	}

	return (
		<ServiceButton
			className="mr-3"
			// href={FarmatodoPaths.ATT_TYPE}
			href={FarmatodoPaths.SELECT(reason, 'consultorio')} // Temporal hasta que se lance la feature de consultas online
			name='BiCalendar'
		>
			Agenda tu cita
		</ServiceButton>
	)
}

export default FarmatodoSpecialistShortcut
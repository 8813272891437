import { auth } from '@/config/DBConnection'
import { get_request } from '@/config/endpoints'
import { IRequest, requestTypes } from '@umahealth/entities'
import axios from 'axios'
import { Timestamp } from 'firebase/firestore'
import { QueryKey, UseQueryOptions, useQuery } from 'react-query'

type QueryOptions = Omit<UseQueryOptions<IRequest<Timestamp>, unknown, IRequest<Timestamp>, QueryKey>, 'queryKey'> | undefined

export function useGetRequest (assignationId: string, type: requestTypes, options?: QueryOptions) {
	return useQuery<IRequest<Timestamp>>({
		queryKey: ['MegalithQuery', 'getRequest', assignationId],
		queryFn: async () => {
			const currentUser = auth?.currentUser
			const token = await currentUser?.getIdToken()

			const headers = {
				Authorization: `Bearer ${token}`,
				'Content-type': 'Application/json',
			}

			const res = await axios.get<IRequest>(get_request(type, assignationId), {headers})
			return res.data
		},
		...options,
	})
}
const homePromotional = {
    image: '/assets/apto-fisico-promotional.png',
    paragraphs: [
        'Gestionalo desde acá y presentalo en el club, el gimnasio o el colegio.',
        'Solicitás, agendás un turno para la consulta y listo. Solo a $10.000'
    ],
    buttonText: 'Solicitar apto físico'
}

const moduleOptions = {
    image: '/assets/apto-fisico-bg.png',
    paragraphs: [
        'Ahorrá tiempo gestionándolo de manera fácil y rápida. En 3 pasos.',
        'Simple y sin esperas.'
    ],
    buttonText: 'Empezar'
}

export const screenOptions = {
    moduleOptions, homePromotional
}

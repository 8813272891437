import { BasicSurveyQuestion, SurveyAppearance } from 'posthog-js'
import React from 'react'
import { INpsSurveyForm } from '../Surveys/NpsSurvey'
import { UseFormRegister } from 'react-hook-form'

interface IOpenQuestion {
	appearance: SurveyAppearance | null,
	question: BasicSurveyQuestion,
	register: UseFormRegister<INpsSurveyForm>,
}

function OpenQuestion({
	appearance,
	question,
	register,
}: IOpenQuestion) {
	return (
		<textarea
			className='w-full bg-white resize-y p-[10px] pb-0 rounded-md overflow-auto border border-solid border-[#c9c6c6] focus:border-grey-3 text-sm'
			rows={5}
			placeholder={appearance?.placeholder}
			{...register(`surveyResponse.${question.originalQuestionIndex}.value`, {
				required: !question.optional ? 'Campo obligatorio' : false
			})}
		/>
	)
}

export default OpenQuestion
'use client'
import React, { useState, useEffect } from 'react'
import SpinnerCountDown from '@/components/GeneralComponents/SpinnerCountDown'
import { Button } from '@umahealth/occipital'
import { useRouter } from 'next/navigation'
import { Modal } from '@umahealth/occipital-ui'
import { setCookie } from 'cookies-next'
import posthog from 'posthog-js'

const InactiveSubscriptionModal = ({subscriptionId}: {subscriptionId: string}) => {
    const [showModal, setShowModal] = useState(true)
    const router = useRouter()

    const onClose = () => {
        posthog.capture('subscription_modal_close')
        setCookie('renew_subscription_view', 'dismiss', {
            maxAge: 24 * 60 * 60 // 24 horas
        })
        setShowModal(false)
        router.refresh()
    }

    if (!showModal) return null
    // TODO: get benefits from backend
    const benefits = [
        {
            text: '5 atenciones al precio de 1',
        },
        {
            text: 'Prioridad en sala de espera',
        },
        {
            text: 'Descuentos en tu bienestar',
        }
    ]

    const WarningIcon = () => (
        <div className='flex justify-center'>
            <svg width="47" height="46" viewBox="0 0 47 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_1_3" style={undefined} maskUnits="userSpaceOnUse" x="0" y="0" width="47" height="46">
                    <rect x="0.5" width="46" height="46" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_1_3)">
                    <path d="M2.41602 40.2526L23.4993 3.83594L44.5827 40.2526H2.41602ZM23.4993 34.5026C24.0424 34.5026 24.4976 34.3189 24.865 33.9516C25.2323 33.5842 25.416 33.129 25.416 32.5859C25.416 32.0429 25.2323 31.5877 24.865 31.2203C24.4976 30.853 24.0424 30.6693 23.4993 30.6693C22.9563 30.6693 22.5011 30.853 22.1337 31.2203C21.7664 31.5877 21.5827 32.0429 21.5827 32.5859C21.5827 33.129 21.7664 33.5842 22.1337 33.9516C22.5011 34.3189 22.9563 34.5026 23.4993 34.5026ZM21.5827 28.7526H25.416V19.1693H21.5827V28.7526Z" fill="#FFCA1B" />
                </g>
                <mask id="mask1_1_3" style={undefined} maskUnits="userSpaceOnUse" x="0" y="0" width="47" height="46">
                    <rect x="0.5" width="46" height="46" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask1_1_3)">
                    <path d="M2.41602 40.2526L23.4993 3.83594L44.5827 40.2526H2.41602ZM9.02852 36.4193H37.9702L23.4993 11.5026L9.02852 36.4193ZM23.4993 34.5026C24.0424 34.5026 24.4976 34.3189 24.865 33.9516C25.2323 33.5842 25.416 33.129 25.416 32.5859C25.416 32.0429 25.2323 31.5877 24.865 31.2203C24.4976 30.853 24.0424 30.6693 23.4993 30.6693C22.9563 30.6693 22.5011 30.853 22.1337 31.2203C21.7664 31.5877 21.5827 32.0429 21.5827 32.5859C21.5827 33.129 21.7664 33.5842 22.1337 33.9516C22.5011 34.3189 22.9563 34.5026 23.4993 34.5026ZM21.5827 28.7526H25.416V19.1693H21.5827V28.7526Z" fill="#1C1B1F" />
                </g>
            </svg>
        </div>
    )

    const BenefitsList = () => (
        <ul className="mb-6 space-y-2 text-center px-4">
            {benefits.map((benefit, index) => (
                <li key={index} className="flex items-center space-x-2 text-blue-600">
                    <span className="w-2 h-2 bg-black rounded-full" />
                    <span>{benefit.text}</span>
                </li>
            ))}
        </ul>
    )

    const ThankYouView = ({ onClose, captureEvent }) => (
        <div>
            <h3 className="m-4 font-extrabold text-l">¡Muchas gracias!</h3>
            <div className="flex flex-col items-center justify-center mb-4">
                <p className="mb-6 text-balance">
                    Recordá que podés activar nuestras suscripciones en cualquier momento desde{' '}
                    <a href="" className='text-blue-600' onClick={() => {
                        posthog.capture(`${captureEvent}_click_subscriptions`)
                        router.push("/subscriptions")
                    }}>acá</a>
                </p>
            </div>
            <div className="flex flex-col gap-2">
                <Button type='button' onClick={() => {
                    posthog.capture(`${captureEvent}_click_understood`)
                    onClose()
                }}>
                    Entendido
                </Button>
            </div>
        </div>
    )

    const ActionButtons = ({ onRenew, onDismiss, eventPrefix }) => (
        <div className="flex flex-col gap-2">
            <Button type='button' onClick={() => {
                posthog.capture(`${eventPrefix}_click_renew`)
                onRenew()
            }}>
                Renovar mi suscripción
            </Button>
            <Button variant='text' type='button' onClick={() => {
                posthog.capture(`${eventPrefix}_click_dismiss`)
                onDismiss()
            }}>
                Prefiero no renovarla
            </Button>
        </div>
    )

    const ModalA = () => {
        const [dismissPromo, setDismissPromo] = useState(false)

        useEffect(() => {
            posthog.capture('subscription_modal_a_view')
        }, [])

        if (dismissPromo) {
            return <ThankYouView onClose={onClose} captureEvent="subscription_modal_a" />
        }

        return (
            <div>
                <WarningIcon />
                <h3 className="m-4 font-extrabold text-l">No pudimos renovar tu suscripción</h3>
                <div className="flex flex-col items-center justify-center mb-4">
                    <p className="mb-6 text-balance">Hacelo ahora y seguí contando con estos beneficios:</p>
                    <BenefitsList />
                </div>
                <p className='mb-4 font-bold text-m'>¿Qué deseas hacer?</p>
                <ActionButtons 
                    onRenew={() => router.push(`/checkout/${subscriptionId}`)}
                    onDismiss={() => setDismissPromo(true)}
                    eventPrefix="subscription_modal_a"
                />
            </div>
        )
    }

    const ModalB = () => {
        const [dismissPromo, setDismissPromo] = useState(0)

        useEffect(() => {
            posthog.capture('subscription_modal_b_view')
        }, [])

        if (dismissPromo === 2) {
            return <ThankYouView onClose={onClose} captureEvent="subscription_modal_b" />
        }

        if (dismissPromo === 1) {
            return (
                <div>
                    <h3 className="m-4 font-extrabold text-l">¿Estás seguro?</h3>
                    <div className="flex flex-col items-center justify-center mb-4">
                        <p className="mb-6 text-balance">Vas a perder estos beneficios:</p>
                        <BenefitsList />
                    </div>
                    <p className='mb-4 font-bold text-m'>¿Qué deseas hacer?</p>
                    <ActionButtons 
                        onRenew={() => router.push(`/checkout/${subscriptionId}`)}
                        onDismiss={() => setDismissPromo(2)}
                        eventPrefix="subscription_modal_b"
                    />
                </div>
            )
        }

        return (
            <div>
                <SpinnerCountDown onClose={onClose} time={60} />
                <WarningIcon />
                <h3 className="m-4 font-extrabold text-l">Tu suscripción no pudo ser renovada</h3>
                <div className="flex flex-col items-center justify-center mb-4">
                    <p className="mb-6 text-balance">¡No te preocupes! Renovala directamente desde acá.</p>
                </div>
                <p className='mb-4 font-bold text-m'>¿Qué deseas hacer?</p>
                <ActionButtons 
                    onRenew={() => router.push(`/checkout/${subscriptionId}`)}
                    onDismiss={() => setDismissPromo(1)}
                    eventPrefix="subscription_modal_b"
                />
            </div>
        )
    }
    if(posthog.getFeatureFlag('suscription-renovation-error-modal') === 'test_group_1'){
        return (
            <Modal noCloseBtn={true} >
                <ModalB />
            </Modal>
        )
    }
    if(posthog.getFeatureFlag('suscription-renovation-error-modal') === 'test_group_2'){
        return (
            <Modal noCloseBtn={true} >
                <ModalA />
            </Modal>
        )
    }
    return (
        <Modal noCloseBtn={true} >
            <ModalA />
        </Modal>
    )
}

export default InactiveSubscriptionModal

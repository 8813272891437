
const PATH = {
  FITNESS_CHECK: '/apto-fisico',
  MEDICAL_GUARD: '/medical-guard',
  ONLINE_DOCTOR: '/onlinedoctor',
}

const MEDICAL_GUARD = {
  HOME: '/medical-guard',
  SELECT_COVERAGE: (patientUid: string) => `/medical-guard/${patientUid}/select-coverage`,
  SELECT_REASON: (patientUid: string) => `/onlinedoctor/${patientUid}/reason/UMA%20MX`
}

const ONLINE_DOCTOR = {
  SCREEN_TO_CHOOSE_REDIRECT: (patientUid: string) => `/onlinedoctor/${patientUid}/screenToChooseRedirect`,
}

const FITNESS_CHECK = {
  HOME: PATH.FITNESS_CHECK,
  SELECT_PATIENT: (patientUid?: string, plan = 'certificate') => {
    if (patientUid) {
      return `${PATH.FITNESS_CHECK}/select-patient/${plan}?patientUid=${patientUid}`
    }
    return `${PATH.FITNESS_CHECK}/select-patient/${plan}`
  },
  SELECT_COVERAGE: (patientUid: string) => `${PATH.FITNESS_CHECK}/select-coverage/${patientUid}`,
  SELECT_QUESTIONS: (patientUid: string, coverage: string) => `${PATH.FITNESS_CHECK}/select-questions/${patientUid}/${coverage}`,
  SELECT_PLAN: `${PATH.FITNESS_CHECK}/select-plan`,
  SELECT_STUDIES: (patientUid: string, coverage: string) => `${PATH.FITNESS_CHECK}/select-studies/${patientUid}/${coverage}`,
  ATTACH_STUDIES: (patientUid: string, coverage: string) => `${PATH.FITNESS_CHECK}/attach-studies/${patientUid}/${coverage}`,
  AVISO: `${PATH.FITNESS_CHECK}/select-patient/aviso`,
  REJECT: `${PATH.FITNESS_CHECK}/select-questions/reject`,
  SUGGESTION: (patientUid: string, coverage: string) => `${PATH.FITNESS_CHECK}/select-studies/suggestion/${patientUid}/${coverage}`,
  AFFIDAVIT: (patientUid: string, coverage: string) => `${PATH.FITNESS_CHECK}/attach-studies/${patientUid}/${coverage}/affidavit`,
  PAY_APPOINTMENT: (patientUid: string, coverage: string, providerUid: string, assignation_id: string, specialty: string) => `${PATH.FITNESS_CHECK}/pay-appointment/${patientUid}/${coverage}/${providerUid}/${assignation_id}/${specialty}`,
  PAYMENT_STATUS: (patientUid: string, coverage: string) => `${PATH.FITNESS_CHECK}/payment-status/${patientUid}/${coverage}`,
}

const SPECIALIST = {
  SELECT_PROVIDER: (patientUid: string, coverage: string) => `/especialista/online/${patientUid}/especialidad/aptofisico?cobertura=${coverage}`
}

export const routes = {
  MEDICAL_GUARD,
  ONLINE_DOCTOR,
  FITNESS_CHECK,
  SPECIALIST
}

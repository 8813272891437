import { fitnessCheckEvents } from '@/events/fitnessCheckEvents'
import { useRouter } from 'next/navigation'

interface CloseButtonProps {
  path: string
  className?: string
  onClose?: () => void
}

export const CloseButton = ({ path, className, onClose }: CloseButtonProps) => {
  const router = useRouter()

  const handleCloseModule = (path: string) => {
    if (onClose) return onClose()
    fitnessCheckEvents.trackEventEntryPageClosed()
    router.push(path)
  }

  return (
    <button
      onClick={() => handleCloseModule(path)}
      className={`absolute mx-auto bg-white rounded-full h-[38px] w-[38px] text-gray-600 hover:text-gray-400 focus:outline-none ${className || ''}`}
    >
      <span className="text-3xl">&times;</span>
    </button>
  )
}

import posthog from 'posthog-js';

/**
 * Registra un evento genérico relacionado con alguna parte del flujo de Suscripciones en PostHog.
 *
 * @param {string} action - La acción que se está rastreando.
 * @param {Record<string, string | number>} [properties] - Propiedades adicionales asociadas al evento.
 */
function trackEventSuscriptions(
    action,
    properties
) {
    posthog.capture(`suscriptions_${action}`, properties);
}

/**
 * Registra un evento relacionado con shortcut en la Home.
 *
 * @param {string} action - La acción específica del atajo que se está rastreando.
 * @param {Record<string, string | number>} [properties] - Propiedades adicionales asociadas al evento.
 */
const trackEventShortcutSuscriptions = (
    action,
    properties
) => {
    trackEventSuscriptions(`shortcut_home_user_${action}`, properties);
};

/**
 * Registra un evento relacionado con banners en la Home.
 *
 * @param {Record<string, string | number>} [properties] - Propiedades adicionales asociadas al evento.
 */
const trackEventBannerSuscriptions = (
    properties
) => {
    trackEventSuscriptions(`banner_home_user`, properties);
};

/**
 * Registra un evento de redirección a las opciones desde la página de Suscripciones.
 *
 * @param {Record<string, string | number>} [properties] - Propiedades adicionales asociadas al evento.
 */
const trackEventPageSuscriptionsRedirectToOptions = (
    properties
) => {
    trackEventSuscriptions(`page_redirect_to_options`, properties);
};

/**
 * Registra un evento de redirección al pago desde la página de Suscripciones.
 *
 * @param {Record<string, string | number>} [properties] - Propiedades adicionales asociadas al evento.
 */
const trackEventPageSuscriptionsRedirectToPayment = (
    properties
) => {
    trackEventSuscriptions(`page_redirect_to_payment`, properties);
};

const trackModalSuscriptionsPending =  (action, properties) => {
    trackEventSuscriptions(`modal_pending_${action}`, properties);
};
/**
 * Conjunto de métodos para rastrear eventos relacionados con Suscripciones.
 */
export const suscriptionEvents = {
    trackEventShortcutSuscriptions,
    trackEventBannerSuscriptions,
    trackEventPageSuscriptionsRedirectToOptions,
    trackEventPageSuscriptionsRedirectToPayment,
    trackModalSuscriptionsPending
};
'use client'
import React, { useState } from 'react'
import { Paragraph } from '@umahealth/occipital'
import { CloseButton } from '@/shared/components/CloseButton/CloseButton'
import SlidingButton from '../ui/SlidingButton/SlidingButton'
import ModalTyC from '../ui/ModalTyC/ModalTyC'
import './styles.css'
import { screenOptions } from '../../domain/entryPageOptions'

interface EntryPageProps {
  from: 'homePromotional' | 'moduleOptions'
  onClose?: () => void
}

const EntryPage = ({
  from = 'moduleOptions',
  onClose
}: EntryPageProps) => {
  const [showModalTyC, setShowModalTyC] = useState<boolean>(false)

  return (
    <section className="bg-cover bg-center bg-no-repeat h-screen p-6 flex flex-col justify-between" style={{ backgroundImage: `url(${screenOptions[from].image})` }}>
      {from === 'homePromotional' && <div className='overlay'/>}
      <div className='relative'>
        <CloseButton onClose={onClose} path='/' className='right-0 bg-opacity-40'/>
      </div>
      <div>
        <div className="text-left">
          <div>
            {from === 'homePromotional' &&     
                <span className="px-2 py-1 mb-4 inline-flex justify-center items-center bg-[#00B186] text-white text-xs font-medium rounded-lg relative z-10">
                  ¡Ya disponible!
                </span>
            }
            <h1 className="text-[64px] textFadeIn fade-in delay-1">APTO</h1>
            <h1 className="text-[64px] textFadeIn fade-in delay-2">FÍSICO</h1>
          </div>
          <div className="mt-6">
            {
              screenOptions[from].paragraphs.map((text, index) => (
                <Paragraph key={index} className='textFadeIn fade-in delay-description'>
                  {text}
                </Paragraph>
              ))
            }
          </div>
        </div>
        <div className="flex justify-center textFadeIn fade-in delay-description">
          <SlidingButton text={
            screenOptions[from].buttonText
          } action={() => setShowModalTyC(!showModalTyC)} />
        </div>
      </div>
      <ModalTyC show={showModalTyC} onClose={() => setShowModalTyC(false)} />
    </section>
  )
}

export default EntryPage

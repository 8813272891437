'use client'
import React, { useEffect } from 'react'
import Logo from '@/components/HomePage/Logo'
import { Button, Paragraph, Spacer, Title } from 'occipital-new'
import styles from './BackToRegister.module.scss'
import { useRouter } from 'next/navigation'
import { IClientState } from '@/config/clients'
import { IPatient } from '@umahealth/entities'
import { saveUtmParams } from '@/context/saveUtmParams'

function BackToRegister({reason, patient, client, searchParams} : {
	reason : 'register' | 'OSS',
	patient: IPatient,
	client: IClientState,
	searchParams: {
		/** utm params para trackear en analytics */
		utm_source?: string,
		/** utm params para trackear en analytics */
		utm_medium?: string,
		/** utm params para trackear en analytics */
		utm_campaign?: string,
	}
}){
	const history = useRouter()
	const path =
    reason === 'register' ? '/register' : '/firstCoverage'

	useEffect(() => {
		saveUtmParams({ objSearchParams: searchParams })
	}, [])

	return <div className={styles['container']}>
		<Logo client={client}/>
		<Spacer value='32px' direction='vertical'/>
		<Title hierarchy='h1' size='l' weight='bold' color='grey-1'> Te damos la bienvenida </Title>
		<Paragraph size='s' weight='regular' color='grey-1'> Tenes un registro iniciado con el email {patient?.email} </Paragraph>
		<div className={styles['bottom']}>
			<Button occ_type='filled' type='button' size='full' action={() => history.push(path)}> Continuar mi registro </Button>
		</div>
	</div>
}

export default BackToRegister

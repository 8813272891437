import { Icon } from '@umahealth/occipital/client'
import styles from '../styles/NpsSurvey.module.scss'
import classNames from 'classnames'
import { ChangeEvent } from 'react'

interface IQuestionChoice {
	id: string,
	indexChoice: number
	isChecked: boolean
	onChange: (e: ChangeEvent<HTMLInputElement>, indexChoice: number) => void
	value: string,
}

/** 
 * Choice component for multiple_choice questions
 * @param id id to relate the label with the input
 * @param value actual value of the input and text of the label
 * @param questionIndex actual question in the survey flow
 * @param update method to update the array of the form through useFieldArray hook
 * @param fields method to obtain all field values of the form through useFieldArray hook
 */
function QuestionChoice({
	id,
	indexChoice,
	isChecked,
	onChange,
	value,
}: IQuestionChoice) {

	return (
		<div
			className={classNames(
				'relative flex cursor-pointer text-xs items-center bg-white rounded border border-solid',
				styles.questionChoice,
				isChecked ? 'border-black font-bold' : 'border-black/25'
			)}
		>
			<input
				type="checkbox"
				className='hidden cursor-pointer'
				id={id}
				name={id}
				value={value}
				onChange={(e) => onChange(e, indexChoice)}
			/>
			<label
				htmlFor={id}
				className='p-[10px] rounded-[4px] w-full'
			>
				{value}
			</label>
			<Icon
				name='check'
				className={classNames(
					'absolute right-[10px] cursor-default',
					styles.check,
					isChecked ? styles.checked : ''
				)}
			/>
		</div>
	)
}

export default QuestionChoice
import React, { useState, useEffect } from 'react'
import styles from './styles.module.css'

type ISpinner = {
	time?: number
	onClose?: () => void
}

const SpinnerCountDown = ({ time, onClose }: ISpinner) => {
	const INITIAL_COUNT = time ?? 10
	const [count, setCount] = useState(INITIAL_COUNT)

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCount((prevCount) => Math.max(prevCount - 1, 0))
		}, 1000)

		const closeEndTime = setTimeout(() => {
			clearInterval(intervalId)
			onClose?.()
		}, INITIAL_COUNT * 1000)

		return () => {
			clearInterval(intervalId)
			clearTimeout(closeEndTime)
		}
	}, [])

	return (
		<div className={styles.timer}>
			<div className={styles.circleTimer}>
				<div className={styles.timerSlot}>
					<div className={styles.timerLt}/>
				</div>
				<div className={styles.count}>{count}</div>
				<div className={styles.timerSlot}>
					<div className={styles.timerRt}/>
				</div>
			</div>
		</div>
	)
}

export default SpinnerCountDown

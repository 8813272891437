'use client'
import React, { useState } from 'react';
import { Paragraph } from '@umahealth/occipital';
import ModalSheet from '@/storybook/components/clientComponents/ModalSheet/ModalSheet';
import ButtonCustom from '@/shared/components/ButtonCustom';
import { useRouter } from 'next/navigation';
import { routes } from '@/constants/routes';
import { fitnessCheckEvents } from '@/events/fitnessCheckEvents';
import Cookies from 'js-cookie';

const ModalTyC = ({ show, onClose }: { show: boolean, onClose: () => void }) => {
  const [consent, setConsent] = useState<boolean>(false);
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const history = useRouter();

  const handleCloseModal = () => {
    Cookies.set('promotionalBanner', 'closed', { 
      path: '/',
      expires: 7,
      sameSite: 'strict'
    });
      fitnessCheckEvents.trackEventAcceptTyCAndRedirect();
      history.push(routes.FITNESS_CHECK.SELECT_PLAN);
      onClose();
    }

  return (
    <ModalSheet
      show={show}
      step={0}
      closeOnClickOutside={true}
      texts={{
        title: 'Términos y Condiciones',
      }}
    >
      <div className='h-full flex flex-col justify-between p-4'>
        <Paragraph className="mb-3 text-left" size="text-xs" weight="font-regular">
          Para realizar la solicitud del Apto Físico, debes aceptar los {'\n'}
          <a href="/apto-fisico/terms-and-conditions" className="text-blue-500 underline">
            Términos y Condiciones.
          </a>
        </Paragraph>
        <div className="space-y-4">
          <div className="flex items-start">
            <input
              id="consent"
              type="checkbox"
              checked={consent}
              onChange={() => setConsent(!consent)}
              className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            />
            <label htmlFor="consent" className="ml-2 text-[12px] text-gray-700 leading-tight">
              Doy mi consentimiento para que ÜMA procese mis datos de salud con el propósito de utilizar el servicio ofrecido.
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="terms"
              type="checkbox"
              checked={termsAccepted}
              onChange={() => setTermsAccepted(!termsAccepted)}
              className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
            />
            <label htmlFor="terms" className="ml-2 text-[12px] text-gray-700">
              He leído y acepto los Términos y Condiciones.
            </label>
          </div>
        </div>
        <ButtonCustom
          disabled={!termsAccepted}
          onClick={() => handleCloseModal()}
        >
          Confirmar
        </ButtonCustom>
      </div>
    </ModalSheet>
  );
};

export default ModalTyC;

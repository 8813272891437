import { FieldArrayWithId, UseFieldArrayAppend, UseFieldArrayUpdate, UseFormRegister, UseFormWatch } from 'react-hook-form'
import { SurveyAppearance, SurveyQuestion } from 'posthog-js'
import { INpsSurveyForm } from '../Surveys/NpsSurvey'
import { Button, Paragraph } from '@umahealth/occipital'
import Question from '../commons/Question'

interface IQuestions {
	appearance: SurveyAppearance | null,
	append: UseFieldArrayAppend<INpsSurveyForm, 'surveyResponse'>,
	fields: FieldArrayWithId<INpsSurveyForm, 'surveyResponse', 'id'>[],
	question: SurveyQuestion,
	questionIndex: number,
	register: UseFormRegister<INpsSurveyForm>,
	update: UseFieldArrayUpdate<INpsSurveyForm, 'surveyResponse'>,
	watch: UseFormWatch<INpsSurveyForm>,
}

/** Return the question */
function Questions({
	appearance,
	append,
	fields,
	question,
	questionIndex,
	register,
	update,
	watch,
}: IQuestions) {

	const isButtonDisabled = () => {
		if (question.optional) return false
		return !watch('surveyResponse')[ questionIndex ]?.value
	}

	return (
		<>
			<Paragraph className='!font-semibold text-sm'>
				{question.question}
			</Paragraph>
			<div className='flex flex-col mt-4'>
				<Question
					appearance={appearance}
					append={append}
					fields={fields}
					question={question}
					questionIndex={questionIndex}
					update={update}
					register={register}
				/>
			</div>
			<Button
				type='submit'
				variant='filled'
				className='w-full mt-3.5 !font-bold'
				disabled={isButtonDisabled()}
			>
				{question.buttonText}
			</Button>
		</>
	)
}

export default Questions
import React from 'react'
import { IPatient, IShortcut } from '@umahealth/entities'
import ButtonService from '@/components/HomePage/Components/ServiceButton/ServiceButton'
import { firestore } from '@/config/DBConnection'
import { IconsNames, Loader } from '@umahealth/occipital'
import useContextUid from '@/services/firebase/useContextUid'
import useTrack from '@/services/hooks/Amplitude/useTrack'
import useActiveServices from '@/services/firebase/useActiveServices'
import useOnlineDoctorAppointment from '@/services/firebase/useOnlineDoctorAppointment'
import { isIomaAppClient, isOspreraClient } from '@/utils/validations/ValidateCoverage'
import { useAssignedMedicalRecord } from '@umahealth/fe-firebase'
import moment from 'moment'
import { ValidRoutes, routes } from '@/OldRouter/Paths/Paths'
import { useRouter } from 'next/navigation'
import { IClientState } from '@/config/clients'
import { useGetRequest } from '@/services/hooks/requests/Requests/useGetRequest'
import { useGetPayment } from '@/services/hooks/requests/Payments/useGetPayment'

interface IOnlineDoctorShortcut {
	shortcut: IShortcut
	user: IPatient|null
	client: IClientState
}

function OnlineDoctorShortcut({ shortcut, client, user }: IOnlineDoctorShortcut) {
	const uid = useContextUid()
	const track = useTrack()
	const activeServices = useActiveServices(firestore, uid??'NO')
	const activeAppointment = useOnlineDoctorAppointment(firestore,
		{
			assignation_id:activeServices.data?.assignation_id === '' ? 'NO' : activeServices.data?.assignation_id??'NO',
			country: process.env.NEXT_PUBLIC_COUNTRY
		}  , {}, {
			enabled: !!activeServices.data?.assignation_id && activeServices.data?.assignation_id != ''
		})
	const lastMedicalRecord = useAssignedMedicalRecord(firestore, uid??'NO')
	let onlineDoctorUrl : 'queue'|'call'|'suspended'|'default'|'ausente' = 'default' 
	const router = useRouter()
	
	const hasActiveAppointment = activeServices.data?.assignation_id != '' && activeAppointment.data?.assignation_id != undefined && activeAppointment.data?.assignation_id != null

	const medicalRecordIsRecent = moment().diff(moment((lastMedicalRecord.data?.timestamps?.dt_booking ?? lastMedicalRecord.data?.timestamps.dt_create)?.toDate()), 'hours') < 48

	const lastRequest = useGetRequest(
		lastMedicalRecord.data?.assignation_id ?? 'NO',
		'online',
		{
			enabled: !!lastMedicalRecord.data?.assignation_id
				&& lastMedicalRecord.data?.payment_data?.id === 'UmaCreditos'
				&& medicalRecordIsRecent,
			retry: 1,
			refetchOnWindowFocus: false,
		}
	)
	const hasValidPaymentData = !!lastMedicalRecord.data?.payment_data?.id
		&& lastMedicalRecord.data?.payment_data?.id !== 'UmaCreditos'
		&& lastMedicalRecord.data?.payment_data?.id !== 'mercadopagoId not found'
		&& lastMedicalRecord.data?.payment_data?.method !== 'Corporate'
		&& lastMedicalRecord.data?.payment_data?.method !== 'Subscription'

	const lastPayment = useGetPayment({
		country: process.env.NEXT_PUBLIC_COUNTRY,
		service: 'guardia',
		paymentId: lastMedicalRecord.data?.payment_data?.id ?? 'NO',
		uid: user?.id || uid || 'NO'
	}, {
		enabled: hasValidPaymentData && medicalRecordIsRecent,
		retry: 1,
		refetchOnWindowFocus: false,
	})

	/** La llamada esta pronta a estar activa */
	if (activeAppointment.data && ['ASSIGN'].includes(activeAppointment.data.state))
	{
		onlineDoctorUrl = 'queue'
	}

	/** La llamada esta activa */
	if (activeAppointment.data && ['ATT'].includes(activeAppointment.data.state))
	{
		onlineDoctorUrl = 'call'
	}

	if (
		!hasActiveAppointment && 
		(
			lastMedicalRecord.data?.mr?.destino_final === 'Paciente ausente' ||
			lastMedicalRecord.data?.mr?.destino_final === 'Anula el paciente' || 
			lastMedicalRecord.data?.mr?.destino_final === 'Anula por falla de conexión'
		) 
			&& medicalRecordIsRecent
			&& !lastRequest.data?.payment_data.refunded
			&& lastPayment.data?.status !== 'refunded' //refunded es cuando se reintegro dinero a traves de mp
			&& lastPayment.data?.status !== 'accredited' // accredited es cuando se reintegro umacreditos
			&& !isIomaAppClient(client)
	){
		onlineDoctorUrl = 'ausente'
	}

	function redirectToModule(redirectRoute: ValidRoutes) {
		track.mutate(
			{ eventInput: `[Home] Clic en módulo ${redirectRoute?.split('/')?.[0]}` }
		)
		router.push(redirectRoute)
	}

	if (activeAppointment.isLoading || lastMedicalRecord.isLoading || lastRequest.isLoading) {
		return <Loader size={'size-5'} color="stroke-primary" />
	}

	if (onlineDoctorUrl === 'ausente'){
		return (
			<ButtonService
				id={shortcut.field}
				isNew={shortcut.new ? true : undefined} 
				name={'warning'} 
				text={'Retomar consulta'} 
				action={()=> redirectToModule(routes.onlinedoctor.done(lastMedicalRecord.data?.assignation_id??'NO'))}
				highlight
			/>
		)
	}

	/** A la sala de espera */
	if (onlineDoctorUrl === 'queue' && activeAppointment.data?.assignation_id){
		return (
			<ButtonService
				id={shortcut.field}
				isNew={shortcut.new ? true : undefined} 
				name={'briefcaseMedical'} 
				text={'Sala de espera'} 
				action={()=> redirectToModule(routes.onlinedoctor.queue(activeAppointment.data?.assignation_id??'NO'))}
				highlight
			/>
		)
	}

	/** Te redirecciono directo a la llamada */
	if (onlineDoctorUrl === 'call'){
		return (
			<ButtonService
				id={shortcut.field}
				isNew={shortcut.new ? true : undefined} 
				name='call' 
				text={'Consulta médica'} 
				action={()=> redirectToModule(routes.onlinedoctor.attention(activeAppointment.data?.assignation_id??'NO'))}
				highlight
			/>
		)
	}

	return (
		<ButtonService
			id={shortcut.field}
			isNew={shortcut.new ? true : undefined} 
			name={shortcut.icon as IconsNames} 
			text={shortcut.text} 
			action={()=> redirectToModule( isOspreraClient(client) ? routes.onlinedoctor.reason(uid??'NO', 'OSPRERA')  : routes.onlinedoctor.who({type: 'online', redirect:'onlinedoctor'}))}
		/>
	)
}

export default OnlineDoctorShortcut

import React from 'react'
import { useRouter } from 'next/navigation'
import db from '@/config/DBConnection'
import styles from '../HomeSlider/Banner.module.scss'
import useTrack from '@/services/hooks/Amplitude/useTrack'
import { useDatalayerMutation } from '@/hooks/useDatalayerAsync'
import Image from '@/reusableComponents/Image'
import { trackEventHomeSlider } from '@/events/homeEvents'

const Slide = ({
	bg = [],
	fullImage = '',
	title = '',
	text = '',
	img = '',
	cta = '',
	tagId = '',
	action = '',
	tracking = ''
} : {
	bg: string[]
	fullImage?: string
	title?: string
	text?: string
	img?: string
	cta?: string
	tagId?: string
	action?: string
	tracking?: string
}) => {
	const arr_text = text.split('|n')
	const track = useTrack()
	const history = useRouter()
	const dataLayerMutation = useDatalayerMutation()

	const trackClick = () => {
		track.mutate(
			{ eventInput: `[Home] Clic en slide ${title}` }
		)
		dataLayerMutation.mutate({ ecommerce: null })
		dataLayerMutation.mutate({
			instance: process.env.NEXT_PUBLIC_DATALAYER_INSTANCE,
			event: 'select_promotion',
			ecommerce: {
				promotion_name: 'Ofertas en top Home',
				creative_slot: 'slider_home',
				items: [
					{
						item_id: `${tracking}`,
						item_name: `${title}`,
					},
				],
			},
			user_id: db.auth().currentUser?.uid,
		})
		trackEventHomeSlider(
			{
				title,
				tracking
			}
		)
		history.push(action)
	}

	return (
			
		<div className={styles['slide']} onClick={trackClick} id={tagId}>
			
			{
				fullImage ? 
					<div className={styles['slide__container']} id={tagId}>
						<div className={styles['slide__fullImage']} id={tagId}>
							<Image src={fullImage} alt={title} id={tagId} className='w-full h-full'/>
						</div>
					</div>
					:
					<>
						<div className={styles['slide__container']} style={{ background: `linear-gradient(90deg, ${bg[0]} 0%, ${bg[1]} 82.01%)` }} id={tagId}>
							<div className={styles['slide__content']} id={tagId}>
								<h1 className={styles['slide__title']} id={tagId}>
									{title}
								</h1>
								<div className={styles['slide__text']} id={tagId}>
									{arr_text.map((line) => (
										<p key={line} className={styles['slide__text']} id={tagId}>
											{line}
										</p>
									))}
								</div>
								<p className={styles['slide__cta']} id={tagId}>
									{cta}
								</p>
							</div>
						</div>
						{
							img && <div className={styles['slide__image']} id={tagId}>
								<Image src={img} alt={title} id={tagId} width={600} height={600} />
							</div>
						}
					</>
			}

		</div>
			
			
	)
}

export default Slide

import { FieldArrayWithId, UseFieldArrayAppend, UseFieldArrayUpdate } from 'react-hook-form'
import { INpsSurveyForm, QuestionResponse } from '../Surveys/NpsSurvey'
import { MultipleSurveyQuestion } from 'posthog-js'
import QuestionChoice from '../commons/QuestionChoice'
import { ChangeEvent, useEffect, useState } from 'react'

interface IMultipleChoiceQuestion {
	append: UseFieldArrayAppend<INpsSurveyForm, 'surveyResponse'>,
	fields: FieldArrayWithId<INpsSurveyForm, 'surveyResponse', 'id'>[],
	question: MultipleSurveyQuestion,
	questionIndex: number,
	update: UseFieldArrayUpdate<INpsSurveyForm, 'surveyResponse'>,
}

function SingleChoiceQuestion ({
	append,
	fields,
	question,
	questionIndex,
	update,
}: IMultipleChoiceQuestion) {
	const initialCheckedArray = Array(question?.choices?.length).fill(false) as boolean[]
	const [ isChecked, setIsChecked ] = useState<boolean[]>(initialCheckedArray)

	const onChange = (e: ChangeEvent<HTMLInputElement>, indexChoice: number) => {
		const dataToSet: QuestionResponse = {
			value: []
		}
		// Selected choice
		const choice = e.target.value

		// Check or uncheck the checkbox
		setIsChecked((prevState) => {
			const newState = initialCheckedArray
			newState[indexChoice] = !prevState[indexChoice]
			return newState
		})

		// Array that contains all selected choices
		const actualValue = fields[ questionIndex ]?.value

		// At the beginning this doesn't exist
		if (!actualValue) {
			// If there are positions undefined in the array between the current question index and first index, we define them
			const missingIndexes = questionIndex - fields.length
			if (missingIndexes > 0) {
				for (let index = 0; index < missingIndexes; index++) {
					append({ value: '' })
				}
			}
			// Set current question value
			dataToSet.value = [ choice ]
			return update(questionIndex, dataToSet)
		}

		// We make sure that the value is an array
		if (typeof actualValue === 'object') {
			// If the selected choice is already in the values of the array
			if (actualValue?.includes(choice)) {
				// We delete it
				dataToSet.value = actualValue.filter((value) => value !== choice)
				return update(questionIndex, dataToSet)
			}

			// If it isn't in the values of the array, we add it
			dataToSet.value = [ choice ]
			update(questionIndex, dataToSet)
		}
	}

	useEffect(() => {
		setIsChecked(initialCheckedArray)
	}, [questionIndex])

	return (
		<div className='flex flex-col gap-1'>
			{question?.choices?.map((choice: string, index: number) => (
				<QuestionChoice
					id={`question_${index}`}
					key={choice}
					value={choice}
					onChange={onChange}
					isChecked={isChecked[index]}
					indexChoice={index}
				/>
			))}
		</div>
	)
}

export default SingleChoiceQuestion
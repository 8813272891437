import React from 'react'
import {
	IPatient,
	HomeParametersObject,
	IShortcut,
	chat,
} from '@umahealth/entities'
import ButtonService from '@/components/HomePage/Components/ServiceButton/ServiceButton'
import { IconsNames } from '@umahealth/occipital'
import SpecialistShortcut from './VariableShorctus/SpecialistShortcut'
import useTrack from '@/services/hooks/Amplitude/useTrack'
import OnlineDoctorShortcut from './VariableShorctus/OnlineDoctorShorcut'
import ChatAttShortcut from './VariableShorctus/ChatAttShortcut'
import { useRouter } from 'next/navigation'
import { IClientState } from '@/config/clients'
import { cn } from '@/lib/utils'
import { SuscripcionesShortcut } from './VariableShorctus/SuscripcionesShortcut'
import { trackEventsHomeModules } from '@/events/homeEvents'
import posthog from 'posthog-js'
import { isAnyIomaClient, isPFA, isUnionPersonal } from '@/utils/validations/ValidateCoverage'

interface IShortcuts {
	shortcutParameter: HomeParametersObject['shorcuts_content']
	user: IPatient | null
	client: IClientState
	chatAtt: chat | null
}

function Shortcuts({
	shortcutParameter: shortcutParameters,
	user,
	client,
	chatAtt,
}: IShortcuts) {
	const router = useRouter()
	const track = useTrack()

	function redirectToModule(moduleLink: string) {
		track.mutate({
			eventInput: `[Home] Clic en módulo ${moduleLink}`,
		})
		router.push(moduleLink)
	}

	const openExternalLink = (externalLink: string) => {
		if (window ) {
			window.open(externalLink, '_blank')
		}
	}

	const navigationHandler = (shortcut: IShortcut) => {
		trackEventsHomeModules(shortcut)
		if (shortcut.externalLink) {
			openExternalLink(shortcut.externalLink)
		} else {
			redirectToModule(shortcut.link)
		}
	}

	return (
		<div
			className={cn(
				'w-full grid grid-cols-2 justify-items-center items-baseline gap-y-3 min-[250px]:grid-cols-3 min-[340px]:grid-cols-4',
				'mt-10', shortcutParameters.shorcuts.filter((shorcut) => shorcut.active).length <= 3 ? 'min-[250px]:grid-cols-3 min-[340px]:grid-cols-3 grid-cols-3' : null)}
		>
			{shortcutParameters.shorcuts
				.sort((a, b) => a.order - b.order)
				.map((shortcut) => {
					if (shortcut.active) {
						/** Todo esto es para que se vea bien el shorcut de 'sala de espera' y 'guardia' */
						if (shortcut.field === 'onlinedoctor') {
							return (
								<OnlineDoctorShortcut
									client={client}
									key={shortcut.field + shortcut.order}
									shortcut={shortcut}
									user={user}
								/>
							)
						}
						if (shortcut.field === 'my_specialist') {
							return (
								<SpecialistShortcut
									client={client}
									key={shortcut.field + shortcut.order}
									shortcut={shortcut}
									user={user}
								/>
							)
						}
						if (shortcut.field === 'chatAtt') {
							return (
								<ChatAttShortcut
									chatAtt={chatAtt}
									client={client}
									key={shortcut.field + shortcut.order}
									shortcut={shortcut}
									user={user}
								/>
							)
						}

						if (shortcut.field === 'subscriptions-shortcut'){
							return <SuscripcionesShortcut shortcut={shortcut} key={shortcut.field + shortcut.order}/>
						}

						if (shortcut.field === 'apto-fisico' && 
							(posthog?.__loaded && !posthog.isFeatureEnabled('apto-fisico')) || 
							(client.freeSpecialists || isAnyIomaClient(client) || isPFA(client) || isUnionPersonal(client))) {
							return null
						}

						return (
							<ButtonService
								key={shortcut.field + shortcut.order}
								id={shortcut.field}
								isNew={shortcut.new ? true : undefined}
								name={shortcut.icon as IconsNames}
								text={shortcut.text}
								action={() => navigationHandler(shortcut)}
							/>
						)
					}
				})}
		</div>
	)
}

export default Shortcuts

import { TReasonFarmaAtt } from "@/app/(providers)/(private)/(callNotification)/home/SpecialistServiceButton.tsx/SpecialistServiceButtonFarmatodo"
import { TAttType } from "../Views/SelectAttTypeView"

const Steps = {
  SELECT_APPOINTMENT: 'SELECT_APPOINTMENT',
  ATT_TYPE: 'ATT_TYPE',
  SELECT: 'SELECT',
} as const

export const FarmatodoPaths = {
  [Steps.ATT_TYPE]: (reason: TReasonFarmaAtt) => `/pharmacovigilance/attType?reason=${reason}`,
  [Steps.SELECT]: (reason: TReasonFarmaAtt, attType: TAttType) => `/pharmacovigilance/select?reason=${reason}&attType=${attType}`,
  [Steps.SELECT_APPOINTMENT]: (reason: TReasonFarmaAtt, attType: TAttType, doctorUid: string) => `/pharmacovigilance/${doctorUid}/selectAppointment?reason=${reason}&attType=${attType}`,
} as const
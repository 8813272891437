import React from 'react'
import './styles.css'

/**
 * SlidingButton component that triggers an action on touch end for mobile devices
 * and on click for desktop devices.
 *
 * - `onTouchEnd`: Used to handle touch events on mobile devices. This is ideal for
 *   handling touch gestures like sliding or swiping.
 * - `onClick`: Fallback for desktop devices where touch events are not available.
 *   Ensures the action is also triggered when the user clicks the button with a mouse.
 *
 * @param {Object} props - Component props.
 * @param {Function} props.action - The function to execute on touch end or click.
 */

const SlidingButton = ({ action, text='Deslizá' }: { action: () => void, text: string }) => (
  <button className="relative w-[200px] h-[100px] mt-10"
    onTouchEnd={action}
    onClick={action}
  >
    <div className="container">
      <div className='arrows'>
        <div className="arrow arrow-one"></div>
        <div className="arrow arrow-two"></div>
      </div>
      <span className='button-text'>
        {text}
      </span>
    </div>
  </button>
)

export default SlidingButton

"use client";

import { useRouter } from 'next/navigation';
import Cookies from 'js-cookie';
import EntryPage from "@/components/FitnessCheck/presentation/views/EntryPage";
import { fitnessCheckEvents } from '@/events/fitnessCheckEvents';
import { useEffect } from 'react';

export default function PromotionalBanner() {
  const router = useRouter();

  useEffect(() => {
    fitnessCheckEvents.trackEventEntryPageFromHomeOpened()
  }, [])
  
  const handleClose = () => {
    fitnessCheckEvents.trackEventEntryPageFromHomeClosed()
    Cookies.set('promotionalBanner', 'closed', { 
      path: '/',
      expires: 7,
      sameSite: 'strict'
    });
    router.refresh();
  };

  return <EntryPage from="homePromotional" onClose={handleClose} />;
}
import { SurveysFlags } from '@/components/HomePage/Utils/surveysFlags'
import { Survey } from 'posthog-js'
import React from 'react'
import NpsSurvey from './Surveys/NpsSurvey'
import { usePostHog } from 'posthog-js/react'
import PhysicalCertSurvey from './Surveys/PhysicalCertSurvey'

export type PosthogSurveysType = Record<keyof SurveysFlags, Survey | null>

interface IPosthogSurveys {
	posthogSurveys: PosthogSurveysType
}

const PosthogSurveys = ({ posthogSurveys }: IPosthogSurveys) => {
	const posthog = usePostHog()

	return (
		<>
			{posthogSurveys.nps && <NpsSurvey survey={posthogSurveys.nps} posthog={posthog} />}
			{posthogSurveys.physicalCert && <PhysicalCertSurvey survey={posthogSurveys.physicalCert} posthog={posthog} />}
		</>
	)
}

export default PosthogSurveys
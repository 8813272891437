import { ITimestamp } from '@umahealth/entities'
import { Timestamp } from 'firebase/firestore'

/**
 * Recorre todo el objeto timestamps y reemplaza las props por nuevas instancias del `FrontendTimestamp` utilizando las propiedades `_seconds` y `_nanoseconds`.
 * Si el objeto proporcionado no tiene estas props, emite una advertencia y devuelve el objeto sin modificar.
 * @param timestamp - `Timestamp` a parsear.
 * @returns - Si el obj es valido, un nuevo obj `ITimestamp` con instancias de `FrontendTimestamp` parseada a un objeto Date, o el valor original si no lo es.
 */
export const parseTimestamps = (timestamps: ITimestamp) => {
	// Recorre todas las propiedades del timestamps
	for (const key in timestamps) {
		const value = timestamps[key]
		// Verifica si es un Timestamp con las propiedades `seconds` y `nanoseconds`
		if (value && typeof value === 'object' && '_seconds' in value && '_nanoseconds' in value) {
			// Crea una nueva instancia de Timestamp a partir de las propiedades `_seconds` y `_nanoseconds`
			timestamps[key] = new Timestamp(value._seconds, value._nanoseconds)
		}
	}
	return timestamps
}

/**
 * Crea una nueva instancia del `FrontendTimestamp` utilizando las propiedades `_seconds` y `_nanoseconds`.
 * Si el objeto proporcionado no tiene estas props, emite una advertencia y devuelve el objeto sin modificar.
 * @param timestamp - `Timestamp` a parsear.
 * @returns - Si el obj es valido, una nueva instancia de `FrontendTimestamp` parseada a un objeto Date, o el valor original si no lo es.
 */
export const parseTimestamp = (timestamp: Timestamp | undefined) => {
	// Verifica si es un Timestamp con las propiedades `seconds` y `nanoseconds`
	if (timestamp && typeof timestamp === 'object' && '_seconds' in timestamp && '_nanoseconds' in timestamp) {
		// Crea una nueva instancia de Timestamp a partir de las propiedades `_seconds` y `_nanoseconds`
		return new Timestamp(timestamp._seconds as number, timestamp._nanoseconds as number).toDate()
	}
	console.warn('[ parseTimestamp ] => timestamp is not valid')
	return timestamp
}

/**
 * Formatea un timestamp a una cadena de fecha y hora.
 * @param timestamp - `Timestamp` a formatear.
 * @returns - Fecha y hora formateadas como cadena.
 */
export const formatTimestampString = (timestamp: Timestamp | undefined): string => {
	const date = parseTimestamp(timestamp);
	if (date instanceof Date) {
		const formattedDate = new Intl.DateTimeFormat('es-ES', {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit',
		}).format(date);

		const formattedTime = new Intl.DateTimeFormat('es-ES', {
			hour: '2-digit',
			minute: '2-digit',
		}).format(date);

		return `${formattedDate} - ${formattedTime} hs`;
	}
	return ''
};

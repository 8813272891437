import { get_mr_pending } from "@/config/endpoints"
import { parseTimestamps } from "@/utils/parseTimestamps"
import { IMedicalRecord } from "@umahealth/entities"
import { getFirebaseIdToken } from "@umahealth/fe-firebase"
import axios, { AxiosError } from "axios"
import { useQuery, UseQueryOptions } from "react-query"

type Options = Omit<UseQueryOptions<IMedicalRecord[], AxiosError>, 'mutationFn' | 'mutationKey'>

export const useGetPendingMedicalRecord = (uid: string, options?: Options) => {
  return useQuery({
    queryKey: ['getPendingMedicalRecord'],
    queryFn: async () => {
      const token = await getFirebaseIdToken()
      const headers = {
        'Content-type': 'Application/json',
        Authorization: `Bearer ${token}`,
        uid,
      }

      const response = await axios.get<IMedicalRecord[]>(get_mr_pending, { headers })

      const parsedMrs = response?.data?.map((mr) => {
        return {
          ...mr,
          timestamps: parseTimestamps(mr.timestamps)
        }
      })

      return parsedMrs
    },
    ...options,
  })
}